import Styles from './page1.module.scss';

const Page1 = () => {
    return (
        <div className={Styles.testcss}>
            hello page 1
        </div>
    )
}

export default Page1;