import Styles from './page2.module.scss';

const Page2 = () => {
    return (
        <div className={Styles.testcss}>
            hello page 2
        </div>
    )
}

export default Page2;